// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// $FlowFixMe
import { ReactComponent as VATIcon } from 'assets/icons/vat.svg';
import styles from './banner.module.scss';
import { USER_ROLES } from 'app/constants';
import type { Props } from './banner.component.types';

export class BannerComponent extends Component<Props> {
  render() {
    const { title, description, linkUrl, linkText, role } = this.props;

    if (role !== USER_ROLES.ROLE_COMPANY_BUYER) {
      return null;
    }

    return (
      <div className={styles.banner}>
        <div>
          <FormattedMessage id={title} tagName="h6" />
          <FormattedMessage id={description} tagName="p" />
          <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id={linkText} />
          </a>
        </div>
        <div className={styles.icon}>
          <VATIcon style={{ height: '48px', width: '48px' }} />
        </div>
      </div>
    );
  }
}
