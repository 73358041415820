// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// $FlowFixMe
import styles from './giftcard-banner.module.scss';
import { USER_ROLES } from 'app/constants';
import type { Props } from './giftcard-banner.component.types';

export class GiftcardBannerComponent extends Component<Props> {
  render() {
    const { role, intl } = this.props;

    if (
      ![
        USER_ROLES.ROLE_VENUE_MANAGER,
        USER_ROLES.ROLE_SERVICE_PROVIDER_MANAGER,
        USER_ROLES.ROLE_VENUE_ACCOUNTANT,
        USER_ROLES.ROLE_VENUE_CASHIER,
      ].includes(role)
    ) {
      return null;
    }

    const smartumOnlineStoreUrl = intl.formatMessage({ id: 'GIFTCARD_BANNER.SMARTUM_ONLINE_STORE_URL' });
    const infoPageUrl = intl.formatMessage({ id: 'GIFTCARD_BANNER.PARTNERS_INFO_PAGE_URL' });
    const helpCenterUrl = intl.formatMessage({ id: 'GIFTCARD_BANNER.HELP_CENTER_URL' });

    return (
      <div className={styles.banner}>
        <div>
          <FormattedMessage id="GIFTCARD_BANNER.TITLE" tagName="h6" />
          <FormattedMessage
            id="GIFTCARD_BANNER.DESCRIPTION"
            tagName="p"
            values={{
              smartumOnlineStoreLink: (
                <a href={smartumOnlineStoreUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="GIFTCARD_BANNER.SMARTUM_ONLINE_STORE" />
                </a>
              ),
              infoPageLink: (
                <a href={infoPageUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="GIFTCARD_BANNER.PARTNERS_INFO_PAGE" />
                </a>
              ),
              helpCenterLink: (
                <a href={helpCenterUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="GIFTCARD_BANNER.HELP_CENTER" />
                </a>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}
